<template>
  <div class="flex column gap-2">
    <h3>Transition</h3>
    <button v-for="transition in availableTransitions" :key="transition" :class="actualTransition == transition ? 'primary':''" @click="appStore.transitionName = transition">{{ transition }}</button>
  </div>
</template>
<script>
import useAppStore from '@/Stores/appStore.js'
import { storeToRefs } from 'pinia'

export default {
  setup () {
    const appStore = useAppStore()
    const { transitionName, availableTransitions } = storeToRefs(appStore)

    return { actualTransition: transitionName, availableTransitions, appStore }
  }
}
</script>
