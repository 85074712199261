<template>
  <div class="card">
    {{ appStore.screenHeight }}
    {{ appStore.screenWidth }}
    width : {{ width}}
    height :{{ height }}
  </div>
</template>

<script>
import useAppStore from '@/Stores/appStore.js'
import { storeToRefs } from 'pinia'

export default {
  name: 'TestPage',
  path: '/test',
  setup () {
    const appStore = useAppStore()

    const { screenHeight, screenWidth } = storeToRefs(appStore)
    return {
      height: screenHeight, width: screenWidth, appStore
    }
  }
}
</script>
