<template>
  <nav class="navBar flex justify-center">

    <div v-for="link in links" :key="link.name">

      <ExpendableNavButton v-if="link.childs" class="flex column" :link="link" />
      <!-- <div v-else> -->

        <router-link v-else :to="link.url"><button class="secondary">{{ link.name }}</button></router-link>

      <!-- </div> -->

    </div>

  </nav>
</template>
<script>
import ExpendableNavButton from '@/Components/ExpendableNavButton.vue'

export default {
  props: ['links'],
  components: {
    ExpendableNavButton
  }

}
</script>
<style scoped>
.navBar {
  gap: 15px;
}

/* .navButton {
  background-color: var(--color-primary);
  border-radius: var(--border-radius-default);
  transition: .1s ease-in-out;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: capitalize;
} */
/*
.navButton:hover {
  background-color: var(--color-primary-light-2);
} */

.navButton.router-link-exact-active {
  background-color: var(--color-primary-light-2);
}
</style>
