<template>
  <div :class="`card bg-${notification.type} pa-3`">
    <h3 class="ma-0">{{ notification.title }}</h3>
    <p class="ma-0">{{ notification.message }}</p>
  </div>
</template>
<script>
import useNotificationStore from '@/Stores/notificationStore.js'

export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const notificationStore = useNotificationStore()
    console.log('notificationStore', props.notification)
    return { notificationStore }
  }
}
</script>
