import { defineStore } from 'pinia'

class Notification {
  constructor (id, type, title, message, createdAt = Date.now(), viewed = false) {
    this.id = id
    this.type = type
    this.title = title
    this.message = message
    this.createdAt = createdAt
    this.viewed = viewed
  }
}

const notificationStore = defineStore('notification', {
  state: () => ({
    notifications: [
      new Notification(0, 'success', 'Welcome to the app!'),
      new Notification(1, 'info', 'This is a notification', 'This is a more detailed notification message'),
      new Notification(2, 'warning', 'This is a warning', 'This is a more detailed warning message'),
      new Notification(3, 'error', 'This is an error', 'This is a more detailed error message')]
  }),
  getters: {
    unviewedNotifs: (state) => state.notifications.filter((notif) => !notif.viewed),
    viewedNotifs: (state) => state.notifications.filter((notif) => notif.viewed)
  },
  actions: {
    newNotification (type, title, message) {
      this.notifications.push(new Notification(this.notifications.length, type, title, message))
    },
    markAsViewed (notification) {
      notification.viewed = true
    },
    markAllAsViewed () {
      this.notifications.forEach((notif) => { notif.viewed = true })
    },
    markAsUnviewed (notification) {
      notification.viewed = false
    },
    markAllAsUnviewed () {
      this.notifications.forEach((notif) => { notif.viewed = false })
    }
  }
})

export default notificationStore
