<template>
  <div class="flex column gap-2">
    <h3>Layouts</h3>
    <button v-for="layout in availableLayouts" :key="layout" :class="actualLayout == layout ? 'primary':''" @click="appStore.layout = layout">{{ layout }}</button>
  </div>
</template>
<script>
import useAppStore from '@/Stores/appStore.js'
import { storeToRefs } from 'pinia'

export default {
  setup () {
    const appStore = useAppStore()
    const { layout, availableLayouts } = storeToRefs(appStore)

    return { actualLayout: layout, availableLayouts, appStore }
  }
}
</script>
