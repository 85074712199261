<template>
    <AdminPanel class="page"/>

</template>

<script>
import AdminPanel from '@/Components/Admin/AdminPanel.vue'

export default {
  name: 'AdminPage',
  path: '/AdminPage',
  components: {
    AdminPanel
  }
}
</script>
