<template>
    <div class="page">
      <TimerHorloge/>
      <TimerButtons/>
    </div>

</template>

<script>
import TimerHorloge from '@/Components/Timer/TimerHorloge.vue'
import TimerButtons from '@/Components/Timer/TimerButtons.vue'

import useAppStore from '@/Stores/appStore.js'

export default {
  name: 'timerPage',
  path: '/',
  components: {
    TimerHorloge,
    TimerButtons
  },
  mounted () {
    const appStore = useAppStore()
    appStore.layout = 'NoneLayout'
  },
  unmounted () {
    const appStore = useAppStore()
    appStore.layout = 'DefaultLayout'
  }
}
</script>
