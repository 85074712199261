<template>
    <div>
      <!-- modify scss variable -->
      <h3>Change colors</h3>
      <div class="flex column gap-2">
        <div v-for="[index, color] in Object.entries(colors).filter(([i, c]) => !i.match(/(dark|light)/))" :key="index"
          class="flex align-center gap-2">
          <input :value="color.value" type="color" @change="eventChangeColor" :title="index">

          <div class="flex column gap-1">
            <div>
              <strong>{{ index }}</strong> <i> {{ hexToRgbString(color.value) }}</i>

            </div>

            <div v-if="['primary', 'secondary', 'accent','success', 'error', 'info', 'warning'].includes(index)" class="flex row">
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index}-light-5)` }" />
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index}-light-4)` }" />
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index}-light-3)` }" />
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index}-light-2)` }" />
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index}-light-1)` }" />
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index})` }" />
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index}-dark-1)` }" />
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index}-dark-2)` }" />
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index}-dark-3)` }" />
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index}-dark-4)` }" />
              <div class="colorTest" :style="{ 'background-color': `var(--color-${index}-dark-5)` }" />
            </div>
          </div>
        </div>

      </div>
    </div>
</template>
<script>
import useAppStore from '@/Stores/appStore.js'
import useColorStore from '@/Stores/colorStore.js'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

export default {
  setup () {
    const colorPanel = ref(true)
    const variableInput = ref('')
    const variableValueInput = ref('')
    const appStore = useAppStore()
    const colorStore = useColorStore()
    const { isAdmin } = storeToRefs(appStore)

    const colors = storeToRefs(colorStore)
    const { changeColor, eventChangeColor, hexToRgbString } = colorStore

    return { colorPanel, variableInput, variableValueInput, isAdmin, colors, colorStore, changeColor, eventChangeColor, hexToRgbString, switchAdmin: appStore.switchAdmin }
  }
}
</script>
<style>
input[type='color'] {
  height: 50px;
}
.colorTest {
  width: 16px;
  height: 16px;
  border: solid 1px black;
}
</style>
