import { defineStore } from 'pinia'

const appStore = defineStore('app', {
  state: () => ({
    menuLinks: [
      { name: 'Home', url: '/' },
      { name: 'Test', url: '/test' },
      { name: 'Timer', url: '/timer' },
      { name: 'Css', url: '/GeneratedCss' },

      {
        name: 'Admin',
        childs: [
          { name: 'adminPage', url: '/adminPage' },
          { name: 'testStore', url: '/testStore' },
          { name: 'designSystem', url: '/designSystem' }
        ]
      }
    ],
    layout: 'DefaultLayout',
    availableLayouts: ['DefaultLayout', 'NoneLayout'],

    transitionName: 'fade', // slide, fade, scale, scale-slide
    availableTransitions: ['slide', 'fade', 'scale', 'scale-slide'],

    isAdmin: true,

    screenHeight: window.innerHeight,
    screenWidth: window.innerWidth
  }),
  getters: {
    transitionMode: (state) => ['fade', 'scale'].includes(state.transitionName) ? 'out-in' : undefined
  },
  actions: {
    switchAdmin () { this.isAdmin = !this.isAdmin },
    addLinks (link) { this.menuLinks.push(link) },
    deleteLinks (linkName) {
      const index = this.menuLinks.findIndex((link) => link.name === linkName)
      this.menuLinks.splice(index, 1)
    }
  }
})

// testStore().$subscribe((state) => {
//   console.log('internal subscribe', state)
// })

export default appStore
