<template>
  <i :class="icon" :style="`font-size: ${size}; color: ${colorFilter}; font-weight: ${weight}`"></i>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },

    size: {
      type: String,
      default: () => '1em'
    },
    color: {
      type: String,
      default: () => 'currentColor'
    },
    weight: {
      type: String,
      default: () => 'normal'
    }
  },
  computed: {
    colorFilter () {
      if (['primary', 'secondary', 'accent'].includes(this.color)) return `var(--color-${this.color})`
      return this.color
    }
  }
}
</script>
