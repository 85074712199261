<template>
  <div class="card flex align-start column gap-6">
    <div  class="flex gap-2 fullsize column" >
          <TimerButton v-for="button in buttons" :key="button" :type="button"/>
    </div>
    <div class="inputContainer fullsize flex gap-2">
      <input type="text" v-model="newSessionName" @keypress.enter="startNewSession"/>
      <button class="primary small" @click="startNewSession">
        New
      </button>
    </div>
  </div>
</template>

<script>
import TimerButton from '@/Components/Timer/TimerButton.vue'
import useTimerStore from '@/Stores/timerStore'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

export default {
  mounted () {
    const timerStore = useTimerStore()
    timerStore.loadFromLocalStorage()
  },
  components: {
    TimerButton
  },
  setup () {
    const newSessionName = ref('')
    const timerStore = useTimerStore()
    const { allType: buttons, lastTimer } = storeToRefs(timerStore)

    const startNewSession = () => {
      timerStore.startSession(newSessionName.value)
      newSessionName.value = ''
    }
    return { buttons, newSessionName, startNewSession, lastTimer, timerStore, resetLocalStorage: timerStore.resetLocalStorage }
  }
}
</script>
<style scoped>
.inputContainer{
  width: 100%;
}
.inputContainer input{
  width: 100%;
  margin: 0px;
}
</style>
