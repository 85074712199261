<template>
<div @mouseleave="openPanel = false" @mouseenter="openPanel = true">

  <button class="bg-secondary-dark-3 flex row align-center"  >
    {{ link.name }} {{ !openPanel ? '🔻' : '🔺'}}
  </button>

  <div class="navMenu flex column" @mouseleave="openPanel = false">
    <router-link v-for="linkChild in openPanel ? link.childs : []" :key="linkChild.name" :to="linkChild.url"
      @click="openPanel = !openPanel">
      <button class="secondary">{{
          linkChild.name
      }}</button></router-link>
  </div>
</div>

</template>
<script>

export default {
  props: ['link'],
  data () {
    return {
      openPanel: false
    }
  }
}
</script>

<style scoped>
.navExpendableButton {
  background-color: var(--color-primary-light-1);
  border-radius: var(--border-radius-default);
  transition: .1s ease-in-out;
  font-weight: bold;
  text-transform: capitalize;
  color: var(--color-primary-dark-5);
}

.navMenu {
  display: flex;
  position: absolute;
  gap: var(--margin-default);
}

</style>
