<template>
  <div class="flex wrap gap-4">
    <ColorsSettings class="card grow py-2"/>
    <!-- modify links -->
    <LayoutSettings class="card grow py-2"/>

    <TransitionSettings class="card grow py-2"/>
  </div>
</template>
<script>
import ColorsSettings from '@/Components/Admin/ColorsSettings.vue'
import LayoutSettings from '@/Components/Admin/LayoutSettings.vue'
import TransitionSettings from '@/Components/Admin/TransitionSettings.vue'

export default {
  components: { ColorsSettings, LayoutSettings, TransitionSettings }
}
</script>
<style>
</style>
