<template>
  <div class="notifPanel flex column gap-2 pa-2">
    <NotificationComponent v-for="notification in unviewedNotifs" :key="notification.id" :notification="notification"/>
    <button class="notifButton" @click="markAllAsViewed">hide</button>
  </div>
</template>

<script>
import NotificationComponent from '@/Components/Notifications/NotificationComponent.vue'
import useNotificationStore from '@/Stores/notificationStore.js'
import { storeToRefs } from 'pinia'

export default {
  components: {
    NotificationComponent
  },
  setup () {
    const notificationStore = useNotificationStore()
    const { markAllAsViewed } = notificationStore
    const { notifications, unviewedNotifs } = storeToRefs(notificationStore)
    return { notificationStore, notifications, unviewedNotifs, markAllAsViewed }
  }
}
</script>

<style>
.notifPanel {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  width: 50%;
  max-width: 400px;
  min-width: 250px;
}
.notifButton {
  width: 75px;
  margin-left: auto;
}
</style>
