const generateCss = (types, values) => {
  const result = []
  for (const type in types) {
    for (const value of values) {
    // document.documentElement.classList.add(`${type}-${value}`, [`${types[type].replace(/XXX/g, value)}`])
      result.push(`.${type}-${value} { ${types[type].replace(/XXX/g, value)} };`)
    }
  }
  return result
}

const spacingTypes = {
  ma: 'margin: calc(var(--margin-default) * XXX)!important;',
  mt: 'margin-top: calc(var(--margin-default) * XXX)!important;',
  mr: 'margin-right: calc(var(--margin-default) * XXX)!important;',
  ml: 'margin-left: calc(var(--margin-default) * XXX)!important;',
  mb: 'margin-bottom: calc(var(--margin-default) * XXX)!important;',
  mx: 'margin-right: calc(var(--margin-default) * XXX)!important; margin-left: calc(var(--margin-default) * XXX)!important;',
  my: 'margin-top: calc(var(--margin-default) * XXX)!important; margin-bottom: calc(var(--margin-default) * XXX)!important;',

  pa: 'padding: calc(var(--padding-default) * XXX)!important;',
  pt: 'padding-top: calc(var(--padding-default) * XXX)!important;',
  pr: 'padding-right: calc(var(--padding-default) * XXX)!important;',
  pl: 'padding-left: calc(var(--padding-default) * XXX)!important;',
  pb: 'padding-bottom: calc(var(--padding-default) * XXX)!important;',
  px: 'padding-right: calc(var(--padding-default) * XXX)!important; padding-left: calc(var(--padding-default) * XXX)!important;',
  py: 'padding-top: calc(var(--padding-default) * XXX)!important; padding-bottom: calc(var(--padding-default) * XXX)!important;',
  gap: 'gap: calc(var(--margin-default) * XXX)!important;'

}
const spacingValues = ['auto', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20]
const spacings = generateCss(spacingTypes, spacingValues)

const colorableTypes = { text: 'color: var(--color-XXX)!important;', bg: 'background-color: var(--color-XXX)!important;', border: 'border: solid 2px var(--color-XXX)!important;' }
const colorableValues = [
  'primary', 'primary-light-1', 'primary-light-2', 'primary-light-3', 'primary-light-4', 'primary-light-5', 'primary-dark-1', 'primary-dark-2', 'primary-dark-3', 'primary-dark-4', 'primary-dark-5',
  'secondary', 'secondary-light-1', 'secondary-light-2', 'secondary-light-3', 'secondary-light-4', 'secondary-light-5', 'secondary-dark-1', 'secondary-dark-2', 'secondary-dark-3', 'secondary-dark-4', 'secondary-dark-5',
  'accent', 'accent-light-1', 'accent-light-2', 'accent-light-3', 'accent-light-4', 'accent-light-5', 'accent-dark-1', 'accent-dark-2', 'accent-dark-3', 'accent-dark-4', 'accent-dark-5',
  'success', 'success-light-1', 'success-light-2', 'success-light-3', 'success-light-4', 'success-light-5', 'success-dark-1', 'success-dark-2', 'success-dark-3', 'success-dark-4', 'success-dark-5',
  'info', 'info-light-1', 'info-light-2', 'info-light-3', 'info-light-4', 'info-light-5', 'info-dark-1', 'info-dark-2', 'info-dark-3', 'info-dark-4', 'info-dark-5',
  'warning', 'warning-light-1', 'warning-light-2', 'warning-light-3', 'warning-light-4', 'warning-light-5', 'warning-dark-1', 'warning-dark-2', 'warning-dark-3', 'warning-dark-4', 'warning-dark-5',
  'error', 'error-light-1', 'error-light-2', 'error-light-3', 'error-light-4', 'error-light-5', 'error-dark-1', 'error-dark-2', 'error-dark-3', 'error-dark-4', 'error-dark-5'
]
const colors = generateCss(colorableTypes, colorableValues)

export default spacings.concat(colors)
