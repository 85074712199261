<template>
  <!-- <AdminPanel/> -->
  <component :is="layout">

    <router-view v-slot="{ Component }" class="router-view-container">
      <transition :name="transitionName" :mode="mode">
        <component :is="Component" />
      </transition>
    </router-view>

  </component>

</template>
<script>
import useAppStore from '@/Stores/appStore.js'
import useColorStore from '@/Stores/colorStore.js'
import { storeToRefs } from 'pinia'

import NoneLayout from '@/Layout/NoneLayout.vue'
import DefaultLayout from '@/Layout/DefaultLayout.vue'

export default {
  components: {
    NoneLayout,
    DefaultLayout
  },
  beforeCreate () {
    const colorStore = useColorStore()
    colorStore.generateAllColors()

    const appStore = useAppStore()
    window.addEventListener('resize', () => {
      appStore.screenWidth = window.innerWidth
      appStore.screenHeight = window.innerHeight
    })
  },

  setup () {
    const appStore = useAppStore()
    const { layout, transitionName, transitionMode, isAdmin } = storeToRefs(appStore)
    return { layout, transitionName, mode: transitionMode, isAdmin }
  },
  watch: {
    $route (to, from) {
      console.log('route changed', to, from)
      // reperer quand une page n'existe pas
    }
  }
}
</script>
<style >
@import '@/Style/index.scss';
</style>
