<template>
  <div class="flex gap-1">
  <div class="flex timerContainer">
    <button
      @click="startSession(lastSession.type)"
      :class="
        (lastSession.inProgress !== false ? 'secondary activeTimer' : '') +
        'flex column justify-center align-center timerButton'
      "
    >
      <div v-if="!editable">
        <div>{{ type }}</div>
        <div class="timerValue">
          {{ lastSession.inProgress ? timeLeftFromLastSession : "" }}
        </div>
      </div>

      <div v-else>
        <input type="text" v-model="newName" @keypress.enter="changeTypeName"  @click.stop="()=>''" />
      </div>
    <div class="timerInfo">
      {{ fullTime }}
    </div>
  </button>

  </div>

  <div class="flex gap-1">
    <button
      class="editButton small flex align-center justify-center pa-0 primary"
      @click="() => (editable = !editable)"
    >
      <Icon size="1.5em" icon="ri-pencil-fill" color="white" />
    </button>
    <button
      class="editButton small flex align-center justify-center pa-0 error"
      @click="deleteSessionType(type)"
    >
      <Icon size="1.5em" icon="ri-delete-bin-fill" color="white" />
    </button>
  </div>
  </div>
  <!-- TODO mettre dans un ordre de derniere utilisation -->
  <!-- TODO choisir une couleur -->
</template>

<script>
import useTimerStore from '@/Stores/timerStore'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import timeBetween from '@/Utils/timeBetween'
import timeDuration from '@/Utils/timeDuration'

import Icon from '@/Components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const timerStore = useTimerStore()
    const type = ref(props.type)
    const newName = ref(props.type)
    const editable = ref(false)

    const { sessions, getLastTimer: lastTimer } = storeToRefs(timerStore)

    const lastSession = computed(() => {
      if (sessions) return timerStore.lastSessionFromTypeComputed(type.value)
      return ''
    })

    const fullTime = computed(() => {
      lastTimer.value
      const fullTimeDuration = timeDuration(
        timerStore.fullTimeFromTypeComputed(type.value)
      )
      return fullTimeDuration || '0 seconde'
    })
    const end = computed(() => lastSession.value.end)
    const startSession = () => {
      if (lastSession.value.inProgress) {
        return timerStore.stopSession(lastSession.value)
      } else {
        return timerStore.startSession(type.value)
      }
    }

    const changeTypeName = () => {
      timerStore.changeTypeName(props.type, newName.value)
      newName.value = ''
    }
    const timeLeftFromLastSession = computed(() => {
      if (lastSession.value.start > lastTimer.value) return '0 seconde'
      return timeBetween(lastSession.value.start, lastSession.value.end)
    })
    return {
      startSession,
      changeTypeName,
      deleteSessionType: timerStore.deleteSessionType,
      newName,
      editable,
      lastSession,
      sessions,
      timeLeftFromLastSession,
      fullTime,
      timerStore,
      end,
      lastTimer
    }
  }
}
</script>
<style scoped>
.timerContainer {
  /* min-width: 270px; */
  width: 100%
}
.timerButton {
  width: 100%;
  max-width: 450px;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.activeTimer {
  box-shadow: 10px 5px 5px black !important;
}

.timerButton:hover .timerInfo {
  opacity: 100;
}
.timerValue {
  text-transform: lowercase;
  color: rgba(0, 0, 0, 0.5);
}

.timerInfo {
  opacity: 0;
  padding: 2px 30px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  text-transform: lowercase;
  font-size: 10px!important;
  align-content: center;
}

.editButton {
  padding: 4px !important;
}
</style>
