import { defineStore } from 'pinia'

const testStore = defineStore('test', {
  state: () => ({ count: 0, users: ['Eduardo', 'Erika'] }),
  getters: {
    doubleCount: (state) => state.count * 2,
    getUserById: (state) => {
      return (userId) => state.users[userId]
    }
  },
  actions: {
    increment () {
      this.count++
    },
    changeUser (userId = 1, newName = 'newUser') {
      this.users[userId] = newName
    }
  }
})

// testStore().$subscribe((state) => {
//   console.log('internal subscribe', state)
// })

export default testStore
