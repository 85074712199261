<template>
  <div class="card bg-secondary">
    <div>

      <div>
        count {{ count }}
      </div>
      <div>
        store.count {{ store.count }}
      </div>
      <div>
        computed.count {{ computedCount }}
      </div>

      <div>
        store.getters.doubleCount {{ store.doubleCount }}
      </div>

      <div>
        <button @click="increment">increment</button>
      </div>

      <div>
        getUserById : {{ getUserById(1) }}
      </div>

      <div>
        <button @click="changeUser(1, 'dfgsefefef')">changeUser</button>
      </div>

    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import useTestStore from '@/Stores/testStore.js'
import { storeToRefs } from 'pinia'

export default {
  name: 'testStore',
  path: '/testStore',

  setup () {
    const store = useTestStore()
    const { count, name } = storeToRefs(store)
    const { increment, doubleCount, getUserById, changeUser } = store

    store.$subscribe((mutation, state) => {
      console.log('$subscribe.mutation', mutation, state) // 'direct' | 'patch object' | 'patch function'
    })
    store.$onAction((action) => {
      console.log('$onAction.action', action)
    })

    return { store, increment, getUserById, changeUser, doubleCount, count, computedCount: computed(() => store.count), name }
  }
}
</script>
