<template>
  <div class="bg-primary-1">
    <div class="pa-6">
      Ceci est la home
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  path: '/home',

  setup () {
    return { }
  }
}
</script>
