import TestStorePage from '@/Router/Pages/TestStorePage.vue'
import DesignSystem from '@/Router/Pages/DesignSystem.vue'
import HomePage from '@/Router/Pages/HomePage.vue'
import AdminPage from '@/Router/Pages/AdminPage.vue'
import TestPage from '@/Router/Pages/TestPage.vue'
import TimerPage from '@/Router/Pages/TimerPage.vue'
import GeneratedCss from '@/Router/Pages/GeneratedCss.vue'

class Page {
  constructor (component) {
    this.path = component.path
    this.name = component.name
    this.component = component
  }
}

const routes = [
  new Page(HomePage),
  new Page(TestStorePage),
  new Page(DesignSystem),
  new Page(AdminPage),
  new Page(TestPage),
  new Page(TimerPage),
  new Page(GeneratedCss)
]
export default routes
