/**
 * Renvoie le temps restant de maneire littéral, entre deux valeur de temps en timestamp
 * @param {Number} startTimestamp - Timestamp de départ
 * @param {Number} endTimestamp - Timestamp de fin
 */
export default (timestamp, long=true) => {
  const diff = timestamp
  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (years > 0) return `${years ? `${years} ans ` : ''}${months ? `${months % 12} mois ` : ''}`
  if (months > 0) return `${months ? `${months % 12} mois ` : ''}${weeks ? `${weeks % 4} sem ` : ''}`
  if (weeks > 0) return `${weeks ? `${weeks % 4} sem ` : ''}${days ? `${days % 7} jour${days > 1 ? 's' : ''} ` : ''}`
  if (days > 0) return `${days ? `${days % 7} jour${days > 1 ? 's' : ''} ` : ''}${hours ? `${hours % 24}heure${hours > 1 ? 's' : ''} ` : ''}`
  if (hours > 0) return `${hours ? `${hours % 24} heure${hours > 1 ? 's' : ''} ` : ''}${minutes ? `${minutes % 60}min` : ''}`
  if (minutes > 0) return `${minutes ? `${minutes % 60} min` : ''} ${seconds ? `${seconds % 60} sec` : ''}`
  return `${seconds ? `${seconds % 60} sec` : ''}`
}
