import { defineStore } from 'pinia'

const timerStore = defineStore('timer', {
  state: () => ({
    sessions: [],
    lastTimer: 0
  }),
  getters: {
    getLastTimer: (state) => {
      return state.lastTimer
    },

    allType: (state) => {
      const typeList = []
      state.sessions.forEach(({ type }) => {
        if (!typeList.includes(type)) typeList.push(type)
      })
      return typeList
    },
    sessionsByTypes: (state) => {
      const result = {}
      for (const t of state.allType) {
        result[t] = state.sessions.filter(({ type }) => type === t)
      }
      return result
    }
  },
  actions: {
    setlastTimer (value) {
      this.lastTimer = value
    },

    lastSessionFromTypeComputed (type) {
      const sessionsArray = this.sessionsByTypes[type]
      if (!sessionsArray?.length) return undefined
      return sessionsArray[sessionsArray.length - 1]
    },

    fullTimeFromTypeComputed (type) {
      return this.sessionsByTypes[type].reduce((acc, { start, end }) => {
        return acc + end - start
      }, 0)
    },

    addSession (type) {
      const session = new Session(this, type)
      this.sessions.push(session)
      return session
    },
    deleteSession (session) {
      const index = this.sessions.findIndex(
        (s) => s.start === session.start && s.type === session.type
      )
      this.sessions.splice(index, 1)
    },

    updateSession (session) {
      const index = this.sessions.findIndex(
        (s) => s.start === session.start && s.type === session.type
      )
      this.sessions.splice(index, 1, session)
    },

    /**
     * Démarre une nouvelle session de temps et cloture celles en cours
     * @param {*} session
     */
    startSession (type) {
      this.sessions.forEach((s) => s.stopSession())
      const session = this.addSession(type)
      session.startSession()
      return session
    },
    stopSession (session) {
      session.stopSession()
    },

    saveToLocalStorage () {
      localStorage.setItem('sessions', JSON.stringify(this.sessions))
    },

    loadFromLocalStorage () {
      const sessions = JSON.parse(localStorage.getItem('sessions'))
      if (!sessions) return
      this.sessions = sessions.map(s => new Session(this, s.type, s.start, s.end))
    },
    resetLocalStorage () {
      this.sessions = []
      this.saveToLocalStorage()
    },
    deleteSessionType (type) {
      this.sessions = this.sessions.filter((s) => s.type !== type)
      this.saveToLocalStorage()
    },

    changeTypeName (type, newName) {
      this.sessions.forEach((s) => {
        if (s.type === type) s.type = newName
      })
      this.saveToLocalStorage()
    }
  }
})

class Session {
  constructor (store, type, start = Date.now(), end = Date.now()) {
    this.getStore = () => store
    this.type = type
    this.start = start
    this.end = end
    this.inProgress = false
  }

  startSession () {
    this.inProgress = setInterval(() => {
      this.end = Date.now()
      this.getStore().setlastTimer(this.end)
      this.getStore().saveToLocalStorage()
    }, 1000)
  }

  stopSession () {
    if (!this.inProgress) return
    clearInterval(this.inProgress)
    this.inProgress = false
  }
}

export default timerStore
