import { defineStore } from 'pinia'

const colorStore = defineStore('colors', {
  state: () => ({
    primary: '#662e91',
    secondary: '#fdcf1b',
    accent: '#eb493a',
    info: '#23a8e1',
    error: '#ff0000',
    success: '#4dff00',
    warning: '#ffbb00'
  }),
  getters: {
    /* Variance de primary */
    'primary-light-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.primary), (x) => x + 20)
      return this.rgbToHex(r, g, b)
    },
    'primary-light-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.primary), (x) => x + 40)
      return this.rgbToHex(r, g, b)
    },
    'primary-light-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.primary), (x) => x + 60)
      return this.rgbToHex(r, g, b)
    },
    'primary-light-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.primary), (x) => x + 80)
      return this.rgbToHex(r, g, b)
    },
    'primary-light-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.primary), (x) => x + 100)
      return this.rgbToHex(r, g, b)
    },

    'primary-dark-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.primary), (x) => x - 20)
      return this.rgbToHex(r, g, b)
    },
    'primary-dark-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.primary), (x) => x - 40)
      return this.rgbToHex(r, g, b)
    },
    'primary-dark-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.primary), (x) => x - 60)
      return this.rgbToHex(r, g, b)
    },
    'primary-dark-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.primary), (x) => x - 80)
      return this.rgbToHex(r, g, b)
    },
    'primary-dark-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.primary), (x) => x - 100)
      return this.rgbToHex(r, g, b)
    },

    /* Variance de secondary */
    'secondary-light-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.secondary), (x) => x + 20)
      return this.rgbToHex(r, g, b)
    },
    'secondary-light-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.secondary), (x) => x + 40)
      return this.rgbToHex(r, g, b)
    },
    'secondary-light-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.secondary), (x) => x + 60)
      return this.rgbToHex(r, g, b)
    },
    'secondary-light-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.secondary), (x) => x + 80)
      return this.rgbToHex(r, g, b)
    },
    'secondary-light-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.secondary), (x) => x + 100)
      return this.rgbToHex(r, g, b)
    },

    'secondary-dark-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.secondary), (x) => x - 20)
      return this.rgbToHex(r, g, b)
    },
    'secondary-dark-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.secondary), (x) => x - 40)
      return this.rgbToHex(r, g, b)
    },
    'secondary-dark-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.secondary), (x) => x - 60)
      return this.rgbToHex(r, g, b)
    },
    'secondary-dark-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.secondary), (x) => x - 80)
      return this.rgbToHex(r, g, b)
    },
    'secondary-dark-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.secondary), (x) => x - 100)
      return this.rgbToHex(r, g, b)
    },

    /* Variance de accent */
    'accent-light-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.accent), (x) => x + 20)
      return this.rgbToHex(r, g, b)
    },
    'accent-light-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.accent), (x) => x + 40)
      return this.rgbToHex(r, g, b)
    },
    'accent-light-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.accent), (x) => x + 60)
      return this.rgbToHex(r, g, b)
    },
    'accent-light-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.accent), (x) => x + 80)
      return this.rgbToHex(r, g, b)
    },
    'accent-light-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.accent), (x) => x + 100)
      return this.rgbToHex(r, g, b)
    },

    'accent-dark-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.accent), (x) => x - 20)
      return this.rgbToHex(r, g, b)
    },
    'accent-dark-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.accent), (x) => x - 40)
      return this.rgbToHex(r, g, b)
    },
    'accent-dark-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.accent), (x) => x - 60)
      return this.rgbToHex(r, g, b)
    },
    'accent-dark-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.accent), (x) => x - 80)
      return this.rgbToHex(r, g, b)
    },
    'accent-dark-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.accent), (x) => x - 100)
      return this.rgbToHex(r, g, b)
    },

    /* Variance de success */
    'success-light-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.success), (x) => x + 20)
      return this.rgbToHex(r, g, b)
    },
    'success-light-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.success), (x) => x + 40)
      return this.rgbToHex(r, g, b)
    },
    'success-light-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.success), (x) => x + 60)
      return this.rgbToHex(r, g, b)
    },
    'success-light-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.success), (x) => x + 80)
      return this.rgbToHex(r, g, b)
    },
    'success-light-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.success), (x) => x + 100)
      return this.rgbToHex(r, g, b)
    },

    'success-dark-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.success), (x) => x - 20)
      return this.rgbToHex(r, g, b)
    },
    'success-dark-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.success), (x) => x - 40)
      return this.rgbToHex(r, g, b)
    },
    'success-dark-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.success), (x) => x - 60)
      return this.rgbToHex(r, g, b)
    },
    'success-dark-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.success), (x) => x - 80)
      return this.rgbToHex(r, g, b)
    },
    'success-dark-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.success), (x) => x - 100)
      return this.rgbToHex(r, g, b)
    },

    /* Variance de error */
    'error-light-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.error), (x) => x + 20)
      return this.rgbToHex(r, g, b)
    },
    'error-light-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.error), (x) => x + 40)
      return this.rgbToHex(r, g, b)
    },
    'error-light-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.error), (x) => x + 60)
      return this.rgbToHex(r, g, b)
    },
    'error-light-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.error), (x) => x + 80)
      return this.rgbToHex(r, g, b)
    },
    'error-light-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.error), (x) => x + 100)
      return this.rgbToHex(r, g, b)
    },

    'error-dark-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.error), (x) => x - 20)
      return this.rgbToHex(r, g, b)
    },
    'error-dark-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.error), (x) => x - 40)
      return this.rgbToHex(r, g, b)
    },
    'error-dark-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.error), (x) => x - 60)
      return this.rgbToHex(r, g, b)
    },
    'error-dark-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.error), (x) => x - 80)
      return this.rgbToHex(r, g, b)
    },
    'error-dark-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.error), (x) => x - 100)
      return this.rgbToHex(r, g, b)
    },

    /* Variance de info */
    'info-light-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.info), (x) => x + 20)
      return this.rgbToHex(r, g, b)
    },
    'info-light-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.info), (x) => x + 40)
      return this.rgbToHex(r, g, b)
    },
    'info-light-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.info), (x) => x + 60)
      return this.rgbToHex(r, g, b)
    },
    'info-light-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.info), (x) => x + 80)
      return this.rgbToHex(r, g, b)
    },
    'info-light-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.info), (x) => x + 100)
      return this.rgbToHex(r, g, b)
    },

    'info-dark-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.info), (x) => x - 20)
      return this.rgbToHex(r, g, b)
    },
    'info-dark-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.info), (x) => x - 40)
      return this.rgbToHex(r, g, b)
    },
    'info-dark-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.info), (x) => x - 60)
      return this.rgbToHex(r, g, b)
    },
    'info-dark-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.info), (x) => x - 80)
      return this.rgbToHex(r, g, b)
    },
    'info-dark-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.info), (x) => x - 100)
      return this.rgbToHex(r, g, b)
    },

    /* Variance de warning */
    'warning-light-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.warning), (x) => x + 20)
      return this.rgbToHex(r, g, b)
    },
    'warning-light-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.warning), (x) => x + 40)
      return this.rgbToHex(r, g, b)
    },
    'warning-light-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.warning), (x) => x + 60)
      return this.rgbToHex(r, g, b)
    },
    'warning-light-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.warning), (x) => x + 80)
      return this.rgbToHex(r, g, b)
    },
    'warning-light-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.warning), (x) => x + 100)
      return this.rgbToHex(r, g, b)
    },

    'warning-dark-1' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.warning), (x) => x - 20)
      return this.rgbToHex(r, g, b)
    },
    'warning-dark-2' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.warning), (x) => x - 40)
      return this.rgbToHex(r, g, b)
    },
    'warning-dark-3' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.warning), (x) => x - 60)
      return this.rgbToHex(r, g, b)
    },
    'warning-dark-4' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.warning), (x) => x - 80)
      return this.rgbToHex(r, g, b)
    },
    'warning-dark-5' (state) {
      const { r, g, b } = this.modifyRgb(this.hexToRgb(state.warning), (x) => x - 100)
      return this.rgbToHex(r, g, b)
    }

  },
  actions: {
    modifyRgbValue (value, modifier) {
      const colorValue = Math.round(modifier(value))
      return colorValue > 255 ? 255 : colorValue > 0 ? colorValue : 0
    },

    modifyRgb ({ r, g, b }, modifier) {
      r = this.modifyRgbValue(r, modifier)
      g = this.modifyRgbValue(g, modifier)
      b = this.modifyRgbValue(b, modifier)
      return { r, g, b }
    },

    changeColor (variableName, value) {
      this[variableName] = value
      this.generateChildColors(variableName)
    },

    generateChildColors (colorName) {
      for (const i in this) {
        if (i.includes(colorName)) {
          const color = this[i]
          document.documentElement.style.setProperty(`--color-${i}`, color)
        }
      }
    },
    generateAllColors () {
      for (const [colorName, colorValue] of Object.entries(this).filter(([key, value]) => key.match(/primary|secondary|accent|info|error|success|warning/))) {
        document.documentElement.style.setProperty(`--color-${colorName}`, colorValue)
      }
    },

    eventChangeColor (event) {
      this.changeColor(event.target.title, event.target.value)
    },

    componentToHex (c) {
      const hex = c.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    },

    rgbToHex (r, g, b) {
      return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b)
    },

    hexToRgbString (hex) {
      const { r, g, b } = this.hexToRgb(hex)
      return this.rgbToString({ r, g, b })
    },

    hexToRgb (hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : { r: 0, g: 0, b: 0 }
    },
    rgbToString ({ r, g, b }) {
      return `rgb(${r}, ${g}, ${b})`
    }
  }
})

export default colorStore
