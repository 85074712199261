<template>
  <div class="card">
    {{ css.join("") }}
  </div>
</template>

<script>
import css from '@/Style/generateScss'

export default {
  name: 'GeneratedCssPage',
  path: '/GeneratedCss',
  setup () {
    return {
      css
    }
  }
}
</script>
