<template>
  <notifications-panel />
  <NavMenu :links="links" test="test" class="ma-4" />
  <div class="page">
    <slot />
  </div>
</template>
<script>
import useAppStore from '@/Stores/appStore.js'
import NavMenu from '@/Components/NavMenu.vue'
import NotificationsPanel from '@/Components/Notifications/NotificationsPanel.vue'

export default {
  components: {
    NavMenu, NotificationsPanel
  },
  setup () {
    const appStore = useAppStore()
    return { links: appStore.menuLinks }
  }
}
</script>
<style>

</style>
